/*=======================================
				Banner
=======================================*/

$sub-banner-bgcolor: darken($primary-color, 20%);
$sub-banner-background-small: url("/images/sub-banner.jpg");
$sub-banner-background: $sub-banner-background-small;

// Banner Heading
$sub-banner-h1-display: block;
$sub-banner-large-height: rem-calc(150);
$sub-banner-h1-padding-small: rem-calc(20 15 20 15);
$sub-banner-h1-padding-medium: $sub-banner-h1-padding-small;
$sub-banner-h1-padding-large: rem-calc(78 15 20 15);
$sub-banner-h1-text-color: dynamic-text-color($sub-banner-bgcolor);
$sub-banner-h1-font-size-small: rem-calc(28);
$sub-banner-h1-font-size: rem-calc(34);
$sub-banner-h1-font-weight: $global-weight-bold;
$sub-banner-h1-border-bottom: none;
$sub-banner-h1-border-spacing: rem-calc(0);

.sub-banner{
	position: relative;
	background-color: $sub-banner-bgcolor;
	background-image: $sub-banner-background-small;
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;

	#sub-banner-container > * {
		@extend h1;
		display: $sub-banner-h1-display;
		margin: $sub-banner-h1-padding-small;
		padding-bottom: $sub-banner-h1-border-spacing;
		border-bottom: $sub-banner-h1-border-bottom;
		color: $sub-banner-h1-text-color;
		font-size: $sub-banner-h1-font-size-small;
		font-weight: $sub-banner-h1-font-weight;
		text-align: center;
	}

	@include breakpoint(medium){
		background-image: $sub-banner-background;

		#sub-banner-container > * {
			margin: $sub-banner-h1-padding-medium;
			color: $sub-banner-h1-text-color;
			font-size: $sub-banner-h1-font-size;
		}
	}

	@include breakpoint(large){
		min-height: $sub-banner-large-height;

		#sub-banner-container > * {
			margin: $sub-banner-h1-padding-large;
		}
	}
}
