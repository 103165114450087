@charset "UTF-8";


/*=======================================
        Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Dependencies for foundation
@import 'vendor/sassy-lists/helpers/missing-dependencies';
@import 'vendor/sassy-lists/helpers/true';
@import 'vendor/sassy-lists/functions/contain';
@import 'vendor/sassy-lists/functions/purge';
@import 'vendor/sassy-lists/functions/remove';
@import 'vendor/sassy-lists/functions/replace';
@import 'vendor/sassy-lists/functions/to-list';

// Settings
// import your own `settings` here or
// import and modify the default settings through
// @import 'settings/settings';
@import "partials/foundation-settings";

// Sass utilities
@import 'vendor/foundation/util/util';

// Global variables and styles
@import 'vendor/foundation/global';

// Components
@import 'vendor/foundation/grid/grid';
// @import 'vendor/foundation/xy-grid/xy-grid';
@import 'vendor/foundation/typography/typography';
@import 'vendor/foundation/forms/forms';
@import 'vendor/foundation/components/visibility';
@import 'vendor/foundation/components/float';
@import 'vendor/foundation/components/button';
@import 'vendor/foundation/components/button-group';
@import 'vendor/foundation/components/accordion-menu';
@import 'vendor/foundation/components/accordion';
// @import 'vendor/foundation/components/badge';
// @import 'vendor/foundation/components/breadcrumbs';
@import 'vendor/foundation/components/callout';
@import 'vendor/foundation/components/card';
@import 'vendor/foundation/components/close-button';
@import 'vendor/foundation/components/drilldown';
@import 'vendor/foundation/components/dropdown-menu';
@import 'vendor/foundation/components/dropdown';
@import 'vendor/foundation/components/flex';
@import 'vendor/foundation/components/responsive-embed';
@import 'vendor/foundation/components/label';
@import 'vendor/foundation/components/media-object';
@import 'vendor/foundation/components/menu';
@import 'vendor/foundation/components/menu-icon';
// @import 'vendor/foundation/components/off-canvas';
// @import 'vendor/foundation/components/orbit';
@import 'vendor/foundation/components/pagination';
// @import 'vendor/foundation/components/progress-bar';
// @import 'vendor/foundation/components/reveal';
// @import 'vendor/foundation/components/slider';
// @import 'vendor/foundation/components/sticky';
// @import 'vendor/foundation/components/switch';
@import 'vendor/foundation/components/table';
@import 'vendor/foundation/components/tabs';
@import 'vendor/foundation/components/title-bar';
@import 'vendor/foundation/components/top-bar';
@import 'vendor/foundation/components/thumbnail';
// @import 'vendor/foundation/components/tooltip';
// @import 'vendor/foundation/prototype/prototype';

@mixin foundation-everything(
  $flex: true,
  $prototype: false
) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }
    @else {
      @include foundation-flex-grid;
    }
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  @include foundation-accordion;
  @include foundation-accordion-menu;
  // @include foundation-badge;
  // @include foundation-breadcrumbs;
  @include foundation-button-group;
  @include foundation-callout;
  @include foundation-card;
  @include foundation-close-button;
  @include foundation-menu;
  @include foundation-menu-icon;
  @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-responsive-embed;
  @include foundation-label;
  @include foundation-media-object;
  // @include foundation-off-canvas;
  // @include foundation-orbit;
  @include foundation-pagination;
  // @include foundation-progress-bar;
  // @include foundation-slider;
  // @include foundation-sticky;
  // @include foundation-reveal;
  // @include foundation-switch;
  @include foundation-table;
  @include foundation-tabs;
  @include foundation-thumbnail;
  @include foundation-title-bar;
  // @include foundation-tooltip;
  @include foundation-top-bar;
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  @if $flex {
    @include foundation-flex-classes;
  }

  @if $prototype {
    @include foundation-prototype-classes;
  }
}

@include foundation-everything();

@import "partials/fonts";
@import "partials/typography";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
@import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";
@import "partials/hlm-functions";

// HLM Base Styles
@import "partials/base";
@import "partials/articles";


/*=======================================
          HLM Modules
=======================================*/
@import "partials/module-includes";

// Banner styles
@import "modules/banner/_banner_full-width";

// Gallery Options
@import "modules/gallery/ThumbnailLayout";
// @import "modules/gallery/ThumbnailSlider";

// HLM Sections
@import "sections/header";
@import "sections/layouts";
@import "sections/footer";
@import "sections/page-home";
@import "sections/sub-banner";

// HLM Other
@import "partials/accordions";
@import "partials/coupons";
@import "partials/blog-grid";
// @import "partials/events";
@import "partials/top-bar";
@import "partials/form-controls";
@import "partials/myce-callouts";
@import "partials/subnav";
// @import "partials/products";
// @import "partials/food-menus";
@import "partials/search";
@import "partials/sml";

// Enhanced Subpage styles
@import "partials/enhanced-subpage";

// HLM PPC
// @import "ppc/main-ppc";

/*=======================================
      Custom Site Styles
=======================================*/
// Additional styles for default button class
.button{
  font-weight: 600;
  border: 1px solid $primary-color;

  &:hover{
    color: $white;
  }

  // Alternate styling
  &.secondary{
    background-color: transparent;
    border: 1px solid #f1f1f1;

    &:hover{
      background-color: $primary-color;
    }
  }
}

// Main content area styles
.content-text{

  // Text links
  a:not(.button){
    font-size: rem-calc(18);
    font-weight: $global-weight-bold;
  }
}

// Homepage section below callouts
.company-info{
  padding: rem-calc(30 0 0);
  background: url(/images/company-info-bg.jpg) no-repeat center top;
  background-size: cover;

  .columns{
    margin: 0 auto rem-calc(30);
  }

  // Wrapper for content
  .company-info-block{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: rem-calc(600);
    height: 100%;
    min-height: rem-calc(470);
    margin: 0 auto;
    padding: rem-calc(40);
    text-align: center;
    background-color: $primary-color;

    .heading{
      color: $white;
      font-size: rem-calc(42) * 0.8;
      font-weight: $global-weight-bold;
    }

    // Bullet items
    ul.custom-list{
      display: inline-block;

      li{
        margin: rem-calc(8 0 6 0);
        color: $white;
        font-size: rem-calc(18);
        text-align: left;
        background-image: url(/images/custom-list-homepage.png)
      }
    }

    // Different background color on hover against blue background
    .button-container .button{
      padding: 1.2em 3em;

      &:hover{
        color: $primary-color;
        background-color: $white;
      }
    }
  }

  @include breakpoint(large){
    padding: rem-calc(120 0);

    .columns{
      margin: 0 auto;
    }
    
    // Wrapper for content
    .company-info-block{
      .heading{
        font-size: rem-calc(42);
      }
    }
  }
}

// Materials page styles
#page-id-288{
  .content-text{

    // Row with products title and description
    & > .row{
      padding: rem-calc(60 0 20);
      text-align: center;
      border-bottom: 1px solid #dcdcdc;

      .columns{
        
        // Image
        &:nth-child(1){
          img{
            @extend .innerframe;
          }
        }

        // Text
        &:nth-child(2){

          // Product title
          h2{
            color: $primary-color;
            font-size: rem-calc(32);
            line-height: 1.2;
          }

          p{
            font-size: rem-calc(18);
            line-height: 1;
          }

          // Row for product details
          .row{
            h3{
              font-size: rem-calc(16);
              text-transform: uppercase;
            }

            p{
              color: #333;
              font-size: rem-calc(16);
              font-weight: 300;
            }
          }
        }
      }

      // Media queries for container for each product row
      @include breakpoint(medium){
        text-align: left;
      }
    }
  }
}