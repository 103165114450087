
//////////////////////////////
// Enhanced subpage styles //
////////////////////////////

// ** Banner Full Width Overrides ** //
$enhanced-banner-bgcolor: #fff;
$enhanced-banner-bgimage: url(/images/enhanced-banner.jpg);

// Banner Heading
$enhanced-banner-heading-margin-small: rem-calc(20 20 10 20);
$enhanced-banner-heading-margin-medium: rem-calc(20 20 10 20);
$enhanced-banner-heading-margin-large: rem-calc(130 0 20 0);
$enhanced-banner-heading-text-color: $white;
$enhanced-banner-heading-font-size: rem-calc(42);
$enhanced-banner-heading-font-weight: $global-weight-bold;
$enhanced-banner-heading-text-multiply: .6;

// Banner Message
$enhanced-banner-message-margin-small: rem-calc(0 20 20 20);
$enhanced-banner-message-margin-medium: rem-calc(0 20 20 20);
$enhanced-banner-message-margin-large: rem-calc(0 0 70 0);
$enhanced-banner-message-text-color: #fff5f5;
$enhanced-banner-message-font-size: rem-calc(28);
$enhanced-banner-message-font-weight: $global-weight-normal;
$enhanced-banner-message-text-multiply: .6;

// Button
$enhanced-banner-button-margin-small: rem-calc(0 10 20 10);
$enhanced-banner-button-margin-medium: rem-calc(0 10 20 10);
$enhanced-banner-button-margin-large: rem-calc(0 10 20 10);
$enhanced-banner-button-font-size: rem-calc(20);
$enhanced-banner-button-text-multiply: .8;

// Wrapper for banner and additional content and main content sections
.enhanced-subpage-wrapper{

    // Banner area
    .enhanced-banner{
        // @extend .banner.full-width-banner;
        position: relative;
        text-align: center;
        background-color: $enhanced-banner-bgcolor;
        background-image: $enhanced-banner-bgimage;
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: cover;
        @include clearfix;

        hr{
            width: rem-calc(45);
            border-bottom: 5px solid transparentize($white, 0.7); 
        }

        .banner-heading{
            margin: $enhanced-banner-heading-margin-small;
            color: $enhanced-banner-heading-text-color;
            font-family: $header-font-family;
            font-size: $enhanced-banner-heading-font-size * $enhanced-banner-heading-text-multiply;
            font-weight: $enhanced-banner-heading-font-weight;
        }

        .banner-message{
            margin: $enhanced-banner-message-margin-small;
            color: $enhanced-banner-message-text-color;
            font-size: $enhanced-banner-message-font-size * $enhanced-banner-message-text-multiply;
            font-weight: $enhanced-banner-message-font-weight;
        }

        // Wrapper for callouts in banner on About page
        .enhanced-banner-callouts{

            .columns{
                margin: 0 auto rem-calc(30);
            }

            // Actual callouts
            .company-highlight-block{
                width: 100%;
                max-width: rem-calc(396);
                height: 100%;
                min-height: rem-calc(569);
                margin: 0 auto;
                padding: rem-calc(40 35);
                background-color: $white;

                .company-highlight-heading{
                    margin: rem-calc(30 0);
                    color: $secondary-color;
                    font-size: rem-calc(24);
                    font-weight: 300;
                }

                .company-highlight-message{
                    color: #333;
                    font-size: rem-calc(16);
                    line-height: 1.8;
                }
            }
        }

        @include breakpoint(medium){
            .banner-heading{
                margin: $enhanced-banner-heading-margin-medium;
		        font-size: $enhanced-banner-heading-font-size;
            }

            .banner-message{
                margin: $enhanced-banner-message-margin-medium;
			    font-size: $enhanced-banner-message-font-size;
            }
        }

        @include breakpoint(large){

            .banner-heading{
                margin: $enhanced-banner-heading-margin-large;
            }

            .banner-message{
                margin: $enhanced-banner-message-margin-large;
            }

            // Wrapper for callouts in banner on About page
            .enhanced-banner-callouts{
                padding: rem-calc(0 0 40);
            }
        }
    }

    // Sections between banner and main content section
    .additional-content-area{

        &:nth-child(even){
            background: #fcfcfc;
        }
    }

    // Main content area
    .content.enhanced-page{
        @include clearfix;

        // Services enhanced subpage
        &.services-page{

            h1{
                color: $secondary-color;
                font-size: rem-calc(28);
                font-weight: $global-weight-bold;
            }
    
            h2{
                color: $secondary-color;
                font-weight: $global-weight-bold;
            }

            hr{
                @extend .section-divider;
            }

            @include breakpoint(large){
                // Wrapper for step label and heading
                .step-wrapper{
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    margin: rem-calc(0 0 30);

                    h1, h2{
                        display: inline-flex;
                        align-items: center;

                        &::before{
                            content: "1";
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-width: rem-calc(81);
                            height: rem-calc(81);
                            margin-right: rem-calc(20);
                            color: $white;
                            font-size: rem-calc(34);
                            font-weight: $global-weight-bold;
                            background-color: $secondary-color;
                        }
                    }

		    @for $i from 2 through 10 {
			    &.step-#{$i} h2::before { 
				    content: "#{$i}";
			    }
		    }
                }

                hr{
                    margin: rem-calc(100) auto;

                    &.last-hr{
                        width: 100%;
                        margin: rem-calc(30) auto;
                        border-bottom: 1px solid #dcdcdc;
                    }
                }
            }
        }

        // About enhanced subpage
        &.about-page{
            text-align: center;

            h1{
                @extend h2;
            }

            // Restore spacing between Headings and HRs
            h1, h2, h3, h4, h5, h6{
                & + hr{
                    margin-top: rem-calc(30);
                }
            }

            hr{
                @extend .section-divider;

                &.last-hr{
                    width: 100%;
                    margin: rem-calc(30) auto;
                    border-bottom: 1px solid #dcdcdc;
                }
            }

            img, .innerframe{
                margin: 0 auto rem-calc(30);
            }

            @include breakpoint(large){
                text-align: left;

                hr{
                    margin-left: 0;
                }

                img, .innerframe{
                    margin: 0;
                }
            }
        }
    }
}
